import axios from "axios";
import React, { Fragment, useEffect, useState } from 'react';

// MUI
import {
    Button, Card,
    CardContent,
    FormControl,
    Grid,
    TextField,
    Typography
} from "@mui/material";


const DownloadBatchCompositesForm = props => {
    const [state, setState] = useState({
        downloadBatchId: '',
        batchPatientIds: [],
        patientDownloadIndex: 0,
        noCompositePatientIds: [],
        downloadFailedPatientIds: []
    })

    const { downloadBatchId, batchPatientIds, patientDownloadIndex, noCompositePatientIds, downloadFailedPatientIds } = state
    const onChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        const bulkDownload = async () => {
            if (batchPatientIds.length > 0) {
                try {
                    const documentRes = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/PatientDocument?patientId=${batchPatientIds[patientDownloadIndex]}`)
                    if (!!documentRes.data.Composite) {
                        const downloadRes = await axios.get(
                            `${process.env.REACT_APP_API_BASE}/v1/PatientDocument/${documentRes.data.Composite.id}/$alt`, {
                            })
                
                        const { signedUrl, fileName } = downloadRes.data
                
                        const aTag = document.createElement('a')
                        aTag.href = signedUrl
                        aTag.target = '_blank'
                        aTag.setAttribute('download', fileName)
                        document.body.appendChild(aTag)
                        aTag.click()
                        aTag.remove()
                    } else {
                        noCompositePatientIds.push(batchPatientIds[patientDownloadIndex])
                    }
                } catch (ex) {
                    console.log(ex)
                    downloadFailedPatientIds.push(batchPatientIds[patientDownloadIndex])
                }
                ((patientDownloadIndex + 1) === batchPatientIds.length) ? setState({ ...state, batchPatientIds: [], patientDownloadIndex: 0}) : setState({ ...state, patientDownloadIndex: patientDownloadIndex + 1})
            }
        }
        bulkDownload()
    }, [state, batchPatientIds, patientDownloadIndex, noCompositePatientIds, downloadFailedPatientIds])

    const onClick = async e => {
        e.preventDefault()
        try {
            const batchRes = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient/batch/${downloadBatchId}`)
            const batchPatientIds = batchRes.data.people.map(patient => patient.id)

            setState({ ...state, batchPatientIds, noCompositePatientIds: [], downloadFailedPatientIds: [] })
        } catch (ex) {
            console.log(ex)
        }
    }


    return <Fragment>
        <Card variant={'outlined'}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} style={{marginBottom: '15px', textTransform: 'uppercase'}}  >
                    Download Batch Composites
                </Typography>
                <Grid container spacing={3}>

                    <Grid item xs={5}>
                        <FormControl fullWidth>
                        <TextField
                            type='text'
                            id='downloadBatchId'
                            name='downloadBatchId'
                            label='Batch Id'
                            value={downloadBatchId}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                            required
                            size={'small'}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={onClick} variant={'contained'} color={'primary'} disabled={!downloadBatchId || batchPatientIds.length > 0}>Download</Button>
                    </Grid>
                </Grid>

                {batchPatientIds.length > 0 && <Typography sx={{ fontSize: 14 }} style={{marginTop: '15px', textTransform: 'uppercase'}}>
                    Downloading Patient {patientDownloadIndex + 1} / {batchPatientIds.length}
                </Typography>}
                {noCompositePatientIds.length > 0 && <Typography sx={{ fontSize: 14 }} style={{marginTop: '15px', textTransform: 'uppercase'}}>
                    Patient Ids With No Composite: {noCompositePatientIds.join(', ')}
                </Typography>}
                {downloadFailedPatientIds.length > 0 && <Typography sx={{ fontSize: 14 }} style={{marginTop: '15px', textTransform: 'uppercase'}}>
                    Patient Ids Where Composite Failed To Download: {downloadFailedPatientIds.join(', ')}
                </Typography>}
            </CardContent>
        </Card>
    </Fragment>
}

export default DownloadBatchCompositesForm