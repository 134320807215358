import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Button
} from "@mui/material"
import Alert from '@mui/material/Alert';
import RouterIcon from '@mui/icons-material/Router';

// context
import { PatientQueryState, sendNewPatientQueryAsync, clearError} from './patientQuerySlice'

import { AuthState } from "../auth/authSlice"

import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }


const SubmitPatientQuery = ({person}) => {

    const { error, recentRequest } = useSelector(PatientQueryState)
    const { user } = useSelector(AuthState)
    const dispatch = useDispatch()

    const {id, firstName, lastName, isCwEnrolled, isActive } = person

    const initialState = {
        open: false,
        openLoader: false
    }
    let disableQuery

    const [dialog, setDialog] = useState(initialState)
    const { open, openLoader } = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = async () => {
        setDialog({...dialog, openLoader: true}) 
        await dispatch(sendNewPatientQueryAsync(id))
        setDialog(initialState)
    }


    /*var dt = new Date()
    const dateOffset = user.organization.defaultDatasourceId === 'CW' ? 1 : 2
    dt.setDate(dt.getDate() - dateOffset);
    var date = dt.toISOString();*/

    if(!isCwEnrolled || !isActive){
        disableQuery = true
    }
    /*if(recentRequest){
        if(date.valueOf() < recentRequest.createdAt.valueOf() && [200,202].includes(recentRequest.status) ){
            disableQuery = true 
        }
    }*/

        return (
            <Fragment>
                <Box display='flex' justifyContent='flex-mid' style={{marginBottom: "10px", marginTop: "10px"}}>
                    <Button
                        variant="contained"
                        size={'small'}
                        disabled={disableQuery}
                        startIcon={<RouterIcon/>}
                        onClick={() => {handleClickOpen()}}>
                        Request from Network

                    </Button>
                </Box>

                <Dialog open={open} id="submitRequestModal" aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoader}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                <DialogTitle id='form-dialog-title'>Submit Records Request</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <DialogContentText>You are about to submit a Records Request for {firstName} {lastName} </DialogContentText>
                        <DialogContentText>Do you wish to continue?</DialogContentText>
                        </Grid>    
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            </Fragment>
        )
}

export default SubmitPatientQuery