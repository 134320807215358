import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'


// mui
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Select,
    InputLabel,
    FormControl,
} from "@mui/material";
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import { setSuccess } from "../people/peopleSlice"
import { getReciprocalFilesAsync } from '../patientQuery/patientQuerySlice';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getDocumentTypesAsync, PatientQueryState } from './../patientQuery/patientQuerySlice';

function PaperComponent(props) {
    return (
      <Draggable
        handle="#pn-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const ProgressNote = ({person}) => {
    const dispatch = useDispatch()

    const initialState = {
        isDialogOpen: false,
        typeId: '',
        selectedFile: null,
        fileError: null,
    }

    const [localState, setLocalState] = useState(initialState)

    useEffect(() => {
        dispatch(getDocumentTypesAsync())
    }, []);

    const { documentTypes } = useSelector(PatientQueryState);

    const { isDialogOpen, selectedFile, typeId } = localState

    const handleClickOpen = () => {
        setLocalState({...localState, isDialogOpen: true})
    }
    const handleClose = () => {
        setLocalState(initialState)
    }

    const onChangeType = e => {
        setLocalState({...localState, typeId: e.target.value})
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('patientId', person.id)
        formData.append('typeId', typeId)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        try {
            const res = await axios
                .post(`${process.env.REACT_APP_API_BASE}/v1/ProgressNote`, formData, config)
                .catch(error => {
                    if (error) {
                        console.log(error.response.data)
                        setLocalState({
                            ...localState,
                            fileError: error.response.data.error,
                            isDialogOpen: true
                        })
                    }
                })

            if (res) { 
                dispatch(setSuccess(true))
                dispatch(getReciprocalFilesAsync(person.id))
                setLocalState(initialState)
            }
        } catch (ex) {
            console.log(ex)
        }
    }

    const onFileChange = e => setLocalState({...localState, selectedFile: e.target.files[0] })

    return (<>
        <Box
            sx={{ mb: 2 }}
            display='flex'
            justifyContent='flex-mid'>
            <Button
                variant="contained"
                size={'small'}
                startIcon={<UploadFileIcon />}
                onClick={() => {handleClickOpen()}}>
                Upload Document
            </Button>
        </Box>

        <Dialog
            id='submitDocument'
            maxWidth={'md'}
            fullWidth={true}
            open={isDialogOpen}
            onClose={handleClose}
            aria-labelledby='pn-dialog-title'
            aria-describedby='pn-dialog-description'
            PaperComponent={PaperComponent}
        >
            <DialogTitle id='pn-dialog-title'>
                Upload Document for {person.firstName} {person.lastName}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 1 }}>
                            <Box sx={{ mb: 1 }}>
                                <DialogContentText>Choose a document type</DialogContentText>
                            </Box>
                            <FormControl variant="outlined" style={{minWidth: 200}}>
                                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Document Type</InputLabel>
                                <Select
                                    native
                                    value={typeId}
                                    onChange={onChangeType}
                                    label="Document type"
                                    size={'small'}
                                >
                                    <option aria-label="None" value="" />
                                    {documentTypes.map(item => (<option key={item.id} value={item.id}>{item.type}</option>))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <Box sx={{ mb: 1 }}>
                                <DialogContentText>Upload a Document *</DialogContentText>
                            </Box>
                            {selectedFile && <Box sx={{ mb: 1 }}>{selectedFile.name}</Box>}
                            <Button variant={'outlined'} component={'label'} size={'medium'}>
                                Choose a File
                                <input type={'file'} name={'file'} onChange={onFileChange} hidden />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={'primary'}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color={'primary'} disabled={!selectedFile}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>

    </>)
}

export default ProgressNote